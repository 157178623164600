// src/components/DoubleImageComparison.jsx
import React from 'react';
import styled from 'styled-components';
import BeforeAfterSlider from './BeforeAfterSlider';
import { useTranslation } from 'react-i18next';
import BlurFade from './BlurFade';
import './DoubleComparisonContainer.css';

const DoubleComparisonContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 95svw;
  max-width: 95svw;
  /* Puedes descomentar la línea de abajo para verificar el contenedor
  background: red; */
`;

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  background: #fff;
`;
// Definir el array de comparaciones
const comparisons = [
    {
        id: 1,
        titleKey: 'Comparar', // Clave de traducción para "Comparar"
        beforeImage: "https://resources.tapichapa.com/comparison/ca1r.jpg",
        afterImage: "https://resources.tapichapa.com/comparison/ca2r.jpg",
    },
    {
        id: 2,
        titleKey: 'Comparar',
        beforeImage: "https://resources.tapichapa.com/comparison/cn1r.jpg",
        afterImage: "https://resources.tapichapa.com/comparison/cn2r.jpg",
    },
    {
        id: 3,
        titleKey: 'Comparar',
        beforeImage: "https://resources.tapichapa.com/comparison/cs1r.jpg",
        afterImage: "https://resources.tapichapa.com/comparison/cs2r.jpg",
    },
    // Puedes agregar más comparaciones aquí si lo deseas
];

const DoubleImageComparison = () => {
    const { t } = useTranslation();

    return (
        <Wrapper id='comparaciones'>
            <DoubleComparisonContainer>
                {comparisons.map((comparison, idx) => (
                    <BlurFade key={comparison.id} delay={0.25 + idx * 0.05} inView className="test">
                        <BeforeAfterSlider
                            title={`${t(comparison.titleKey)} ${comparison.id}`}
                            beforeImage={comparison.beforeImage}
                            afterImage={comparison.afterImage}
                        />
                    </BlurFade>
                ))}
            </DoubleComparisonContainer>
        </Wrapper>
    );
};

export default DoubleImageComparison;
