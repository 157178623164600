// src/components/Header.jsx
import React, { useState, useEffect, useRef } from 'react';
import styled, { createGlobalStyle } from 'styled-components';
import { useTranslation } from 'react-i18next';
// import logo from '../assets/images/logo.png';
// import logo from 'https://resources.tapichapa.com/tapichapa.svg';

// Estilos Globales para scroll suave
const GlobalStyle = createGlobalStyle`
  html {
    scroll-behavior: smooth;
  }
`;

const HeaderContainer = styled.header`
  background-color: #2c3e50;
  color: #fff;
  padding: 20px 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

// Logo estilizado
const Logo = styled.img`
  margin-left: 10%;
  height: 50px;
`;

// Navegación estilizada
const Nav = styled.nav`
  margin-right: 10%;
  ul {
    list-style: none;
    display: flex;
    padding: 0;
    margin: 0;
  }

  ul li {
    margin-left: 20px;
  }

  ul li a {
    color: #fff;
    text-decoration: none;
    font-weight: bold;
    transition: color 0.3s;
    cursor: pointer;
  }

  ul li a:hover {
    color: #e67e22;
  }

  @media (max-width: 768px) {
    ul {
      flex-direction: column;
      background-color: #2c3e50;
      position: absolute;
      top: 70px; /* Altura del Header */
      left: ${({ isOpen }) => (isOpen ? '0' : '-100%')};
      width: 100%;
      transition: left 0.3s ease-in-out;
    }

    ul li {
      margin: 15px 0;
      text-align: center;
    }
  }
`;

// Botón de toggle del menú con animación
const MenuToggle = styled.button`
  order: 3;
  display: none;
  flex-direction: column;
  justify-content: space-around;
  width: 30px;
  height: 25px;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  box-sizing: border-box;
  z-index: 1100; /* Asegura que esté por encima del Nav */

  &:focus {
    outline: none;
  }

  @media (max-width: 768px) {
    display: flex;
    margin-right: 10%;
  }

  span {
    width: 30px;
    height: 3px;
    background: #fff;
    border-radius: 2px;
    transition: all 0.3s linear;
    position: relative;
    transform-origin: 1px;

    /* Animaciones cuando el menú está abierto */
    ${({ isOpen }) =>
    isOpen &&
    `
      &:first-child {
        transform: rotate(45deg);
      }

      &:nth-child(2) {
        opacity: 0;
        transform: translateX(20px);
      }

      &:last-child {
        transform: rotate(-45deg);
      }
    `}
  }
`;

// Selector de idioma estilizado
const LanguageSelector = styled.select`
  margin-left: ${(props) => (props.isPhone ? '0px' : '20px')};
  padding: 5px;
  border-radius: 4px;
  border: none;
  cursor: pointer;
`;

// Hook personalizado para detectar el tipo de dispositivo
const useGetUserAgent = () => {
  const [deviceType, setDeviceType] = useState('PC');

  useEffect(() => {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;

    if (/android/i.test(userAgent)) {
      setDeviceType('Android');
    } else if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      setDeviceType('iOS');
    } else if (/windows phone/i.test(userAgent)) {
      setDeviceType('Windows Phone');
    } else {
      setDeviceType('PC');
    }
  }, []);

  return deviceType;
};

// Componente Header
const Header = () => {
  const { t, i18n } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const deviceType = useGetUserAgent();
  const navRef = useRef(null);
  const toggleRef = useRef(null); // Referencia para MenuToggle

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const changeLanguage = (e) => {
    i18n.changeLanguage(e.target.value);
  };

  // Función para cerrar el menú al hacer clic fuera de él
  const handleClickOutside = (event) => {
    if (
      navRef.current &&
      !navRef.current.contains(event.target) &&
      toggleRef.current &&
      !toggleRef.current.contains(event.target)
    ) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    if (isOpen) {
      document.addEventListener('mousedown', handleClickOutside);
      document.addEventListener('touchstart', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('touchstart', handleClickOutside);
    }

    // Limpieza del efecto
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('touchstart', handleClickOutside);
    };
  }, [isOpen]);

  // Manejador para cerrar el menú al seleccionar una opción
  // const handleMenuItemClick = () => {
  //   setIsOpen(false);
  // };

  // Función para manejar el scroll con offset
  const handleScroll = (id) => {
    const element = document.getElementById(id);
    if (element) {
      const headerOffset = 90; // Debe coincidir con la altura del Header
      const elementPosition = element.getBoundingClientRect().top;
      const offsetPosition = elementPosition + window.pageYOffset - headerOffset;

      window.scrollTo({
        top: offsetPosition,
        behavior: 'smooth',
      });

      setIsOpen(false); // Cierra el menú después de hacer scroll
    }
  };

  return (
    <>
      <GlobalStyle />
      <HeaderContainer>
        <Logo src='https://resources.tapichapa.com/tapichapaV2.svg' alt="Logo de TAPICHAPA" />
        {/* <Logo src='https://resources.tapichapa.com/tapichapa.svg' alt="Logo de TAPICHAPA" /> */}
        <MenuToggle
          onClick={toggleMenu}
          ref={toggleRef}
          aria-label="Toggle Menu"
          isOpen={isOpen}
        >
          <span />
          <span />
          <span />
        </MenuToggle>
        <Nav isOpen={isOpen} ref={navRef}>
          <ul>
            <li>
              <a onClick={() => handleScroll('inicio')}>{t('Inicio')}</a>
            </li>
            <li>
              <a onClick={() => handleScroll('mision-objetivos')}>{t('Misión y Objetivos')}</a>
            </li>
            <li>
              <a onClick={() => handleScroll('comparaciones')}>{t('Comparaciones')}</a>
            </li>
            {/* <li>
              <a onClick={() => handleScroll('servicios')}>{t('Servicios')}</a>
            </li> */}
            <li>
              <a onClick={() => handleScroll('testimonios')}>{t('Testimonios')}</a>
            </li>
            <li>
              <a onClick={() => handleScroll('contacto')}>{t('Contacto')}</a>
            </li>
            <li>
              <LanguageSelector
                onChange={changeLanguage}
                value={i18n.language}
                isPhone={deviceType === 'Android' || deviceType === 'iOS'}
                aria-label="Seleccionar Idioma"
              >
                <option value="es">ES</option>
                <option value="en">EN</option>
              </LanguageSelector>
            </li>
          </ul>
        </Nav>
      </HeaderContainer>
    </>
  );
};

export default Header;
