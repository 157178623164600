// src/components/MissionObjectives.jsx
import React from 'react';
import styled, { keyframes } from 'styled-components';
import { useTranslation } from 'react-i18next';
import smileIcon from '../assets/images/smile.png'; // Ícono de sonrisa
import moneyIcon from '../assets/images/money.png'; // Ícono de dinero
import toolIcon from '../assets/images/tool.png'; // Ícono de herramienta
import BlurFade from './BlurFade';

// Definir la animación del arcoíris
const rainbowAnimation = keyframes`
  0% {
    background-position: 0% 50%;
  }
  100% {
    background-position: 100% 50%;
  }
  // 100% {
  //   background-position: 0% 50%;
  // }
`;

// Estilos del Section
const Section = styled.section`
  padding: 100px 10%;
  background-color: #ecf0f1;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

// Estilos del Título
const Title = styled.h2`
  margin-bottom: 20px;
  color: #2c3e50;
`;

// Estilos del Contenido de la Misión
const Mission = styled.div`
  max-width: 800px;
  text-align: center;
  margin-bottom: 40px;
`;

// Contenedor de Objetivos
const ObjectivesContainer = styled.div`
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
`;

// Estilos de la Tarjeta de Objetivo con Efecto Arcoíris
const ObjectiveCard = styled.div`
  position: relative;
  background-color: #fff;
  width: 250px;
  text-align: center;
  margin: 20px;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  height: 100%;
  max-height: 70%;
  overflow: hidden;
  z-index: 1;
  transition: box-shadow 0.3s ease;

  /* Pseudo-elemento para el efecto arcoíris */
  &::before {
    content: '';
    position: absolute;
    top: -50%;
    left: -50%;
    width: 200%;
    height: 200%;
    background: repeating-linear-gradient(
      45deg,
      hsl(0 100% 63%),
      hsl(270 100% 63%),
      hsl(210 100% 63%),
      hsl(195 100% 63%),
      hsl(90 100% 63%),
      hsl(195 100% 63%),
      hsl(210 100% 63%),
      hsl(270 100% 63%),
      hsl(0 100% 63%)
      
      // hsl(var(--color-1)), 
      // hsl(var(--color-2)),
      // hsl(var(--color-3)),
      // hsl(var(--color-4)),
      // hsl(var(--color-5)),
      // hsl(var(--color-1))
    );
    background-size: 400% 400%;
    animation: ${rainbowAnimation}  5s linear infinite;
    filter: blur(15px);
    opacity: 0.6;
    z-index: -1;
  }

  /* Efecto al pasar el ratón */
  &:hover {
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
  }
`;

// Estilos del Icono
const Icon = styled.img`
  width: 50px;
  height: 50px;
  margin-bottom: 10px;
`;

// Título de la Tarjeta
const ObjectiveTitle = styled.h3`
  color: #2c3e50;
  margin-bottom: 10px;
  -webkit-text-stroke: 0.5px white;
  // text-stroke: 2px white;
`;

// Descripción de la Tarjeta
const ObjectiveDescription = styled.p`
  color: #7f8c8d;
`;

const MissionObjectives = () => {
  const { t } = useTranslation();

  // Definir el array de objetivos
  const objectives = [
    {
      id: 1,
      icon: smileIcon,
      altKey: 'Satisfacción del Cliente',
      titleKey: 'Satisfacción del Cliente',
      descriptionKey: 'objetivo1',
    },
    {
      id: 2,
      icon: moneyIcon,
      altKey: 'Ahorro Económico',
      titleKey: 'Ahorro Económico',
      descriptionKey: 'objetivo2',
    },
    {
      id: 3,
      icon: toolIcon,
      altKey: 'Calidad y Excelencia',
      titleKey: 'Calidad y Excelencia',
      descriptionKey: 'objetivo3',
    },
    // Puedes agregar más objetivos aquí si lo deseas
  ];

  return (
    <Section id="mision-objetivos">
      <Title>{t('Misión y Objetivos')}</Title>
      <Mission>
        <p>{t('mision')}</p>
      </Mission>
      <ObjectivesContainer>
        {objectives.map((objective, idx) => (
          <BlurFade key={objective.id} delay={0.25 + idx * 0.05} inView>
            <ObjectiveCard>
              <Icon src={objective.icon} alt={t(objective.altKey)} />
              <ObjectiveTitle>{t(objective.titleKey)}</ObjectiveTitle>
              <ObjectiveDescription>{t(objective.descriptionKey)}</ObjectiveDescription>
            </ObjectiveCard>
          </BlurFade>
        ))}
      </ObjectivesContainer>
    </Section>
  );
};

export default MissionObjectives;
