// src/components/Testimonials.jsx
import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import testimonial1 from '../assets/images/testimonial1.jpg'; // Imagen mock de cliente 1
import testimonial2 from '../assets/images/testimonial2.jpg'; // Imagen mock de cliente 2
import testimonial3 from '../assets/images/testimonial3.jpg'; // Imagen mock de cliente 3
import BlurFade from './BlurFade';

const Section = styled.section`
  padding: 50px 10%;
  background-color: #ecf0f1;
`;

const Title = styled.h2`
  text-align: center;
  margin-bottom: 40px;
  color: #2c3e50;
`;

const TestimonialsContainer = styled.div`
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
`;

const TestimonialCard = styled.div`
  background-color: #fff;
  max-width: 300px;
  margin: 20px;
  padding: 20px;
  border-radius: 8px;
  text-align: center;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
`;

const TestimonialImage = styled.img`
  width: 80px;
  height: 80px;
  border-radius: 50%;
  object-fit: cover;
  margin-bottom: 15px;
`;

const TestimonialText = styled.p`
  color: #7f8c8d;
  margin-bottom: 10px;
`;

const TestimonialAuthor = styled.strong`
  color: #2c3e50;
`;

// Definir el array de testimonios
const testimonials = [
  {
    id: 1,
    image: testimonial1,
    alt: 'Cliente 1',
    textKey: 'testimonio1',
    author: 'Juana Pérez',
  },
  {
    id: 2,
    image: testimonial2,
    alt: 'Cliente 2',
    textKey: 'testimonio2',
    author: 'María López',
  },
  {
    id: 3,
    image: testimonial3,
    alt: 'Cliente 3',
    textKey: 'testimonio3',
    author: 'Carla García',
  },
  // Puedes agregar más testimonios aquí si lo deseas
];

const Testimonials = () => {
  const { t } = useTranslation();

  return (
    <Section id="testimonios">
      <Title>{t('Testimonios de Clientes')}</Title>
      <TestimonialsContainer>
        {testimonials.map((testimonial, idx) => (
          <BlurFade key={testimonial.id} delay={0.25 + idx * 0.05} inView>
            <TestimonialCard>
              <TestimonialImage src={testimonial.image} alt={t(testimonial.alt)} />
              <TestimonialText>"{t(testimonial.textKey)}"</TestimonialText>
              <TestimonialAuthor>- {testimonial.author}</TestimonialAuthor>
            </TestimonialCard>
          </BlurFade>
        ))}
      </TestimonialsContainer>
    </Section>
  );
};

export default Testimonials;
