import React, { useState, useEffect } from 'react';
import './FooterPoweredBy.css';
import LegalFooter from './LegalFooter';
// import { PText } from './text.wrapper';
// import { PDivider } from './test3';

const FooterPoweredBy = ({ isPhone: propIsPhone, PProvider, justifyFooter, enableInternalIsPhoneCheck = false }) => {
    const [isPhone, setIsPhone] = useState(propIsPhone);
    const spacingFluidMedium = "clamp(16px, 1.25vw + 12px, 36px)";
    const spacingFluidSmall = "clamp(8px, 0.5vw + 6px, 16px)";
    const spacingFluidXSmall = "clamp(4px, 0.25vw + 3px, 8px)";
    useEffect(() => {
        if (enableInternalIsPhoneCheck) {
            const userAgent = navigator.userAgent || navigator.vendor || window.opera;
            let device = 'PC';

            if (/android/i.test(userAgent)) {
                device = "Android";
            } else if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
                device = "iOS";
            } else if (/windows phone/i.test(userAgent)) {
                device = "Windows Phone";
            }

            setIsPhone(device !== 'PC');
        }
    }, [enableInternalIsPhoneCheck]);

    const footerSelectBodyHeadStyling = {
        gap: isPhone ? "" : spacingFluidXSmall,
        flexDirection: isPhone ? "column" : "row",
    };

    const footerSelectBodyStyling = {
        margin: isPhone
            ? `${spacingFluidMedium} ${spacingFluidSmall} ${spacingFluidSmall} ${spacingFluidSmall}`
            : `${spacingFluidSmall} ${spacingFluidSmall} ${spacingFluidSmall} ${spacingFluidSmall}`,
    };

    const subStyling = isPhone ? "xx-small" : "x-small";

    const justifyFooterStle = {
        justifyContent: !justifyFooter ? "end" : justifyFooter,
        alignItems: !justifyFooter ? "end" : justifyFooter,
        background: `linear-gradient(${!justifyFooter ? "270deg" : "-270deg"}, rgba(31, 31, 31, .9) 0, rgba(31, 31, 31, .9) 20%, rgba(31, 31, 31, .852589) 26.67%, rgba(32, 32, 32, .768225) 33.33%, rgba(33, 33, 33, .668116) 40%, rgba(34, 34, 34, .557309) 46.67%, rgba(35, 35, 35, .442691) 53.33%, rgba(36, 36, 36, .331884) 60%, rgba(37, 37, 37, .231775) 66.67%, rgba(38, 38, 38, .147411) 73.33%, rgba(39, 39, 39, .0816599) 80%, rgba(39, 39, 39, .03551) 86.67%, rgba(39, 39, 39, .0086472) 93.33%, rgba(39, 39, 39, 0))`,
    };
    console.log(subStyling);
    const content = (
        <div className="footerPoweredBy" style={{position: "relative"}}>
            <div class="footerSelect" style={justifyFooterStle}>

                <hr class="divider" />
                <a target="_blank" href='https://agenciaSkPublicidad.com' className="skLink">
                    <div class="footerSelectBody" style={footerSelectBodyStyling}>
                        <div class="footerSelectBodyHead" style={footerSelectBodyHeadStyling}>
                            <p className="x-small">Powered by</p>
                            <p className="medium">SK Gate</p>
                        </div>
                        <p className={subStyling}>The Gate To Your Dreams</p>

                    </div>
                </a>
            </div>
            <LegalFooter />

        </div>
    );
    const poweredStyling = {
        // backgroundColor: "#e67e22"
        backgroundColor: "#2c3e50"
        // backgroundColor: "whitesmoke"
    }
    return (
        <div
            className='footerPoweredBy'
            style={poweredStyling}
        >
            {!PProvider ? (
                (content)
            )
                :
                (content)
            }
        </div>
    );
};

export default FooterPoweredBy;
