// src/components/BeforeAfterSlider.jsx
import React, { useRef, useState, useEffect, useCallback } from 'react';
import styled, { keyframes, css } from 'styled-components';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next'; // Importa useTranslation

// Definir la animación de pulso
const pulse = keyframes`
  0% {
    transform: scale(1);
    opacity: 1;
  }
  100% {
    transform: scale(2);
    opacity: 0.25;
  }
`;

// Contenedor del slider
const SliderContainer = styled.div`
  position: relative;
  width: 100%;
  max-width: 800px; /* Ajusta según tus necesidades */
  height: 400px; /* Ajusta según tus necesidades */
  margin: 20px 0;
  border: 2px solid #ddd;
  border-radius: 10px;
  user-select: none;

  /* Pseudo-elemento ::before para el background que desaparecerá */
  &::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.65);
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: ${({ hasInteracted }) => (hasInteracted ? "0" : "1")};
    z-index: 4;
    transition: opacity 0.5s ease; /* Transición suave */
  }
`;

// SliderBar: Línea vertical en la posición del slider
const SliderBar = styled.div`
  position: absolute;
  top: 0;
  left: ${({ sliderPosition }) => `${sliderPosition}%`};
  transform: translateX(-50%); /* Centrar horizontalmente */
  width: 4px; /* Ancho reducido para que sea una barra fina */
  height: 100%;
  background-color: #e4e4e4de;
  z-index: 3;
  transition: opacity 0.5s ease;
  pointer-events: none; /* Para que no interfiera con la interacción del usuario */
`;


// Imagen base
const Image = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

// Imagen "Después" con clip-path
const AfterImage = styled(Image)`
  z-index: 1;
  clip-path: ${({ sliderPosition }) => `polygon(0 0, ${sliderPosition}% 0, ${sliderPosition}% 100%, 0 100%)`};
  /* Eliminado transition para mayor velocidad */
`;

// Imagen "Antes" con clip-path
const BeforeImage = styled(Image)`
  z-index: 2;
  clip-path: ${({ sliderPosition }) => `polygon(${sliderPosition}% 0, 100% 0, 100% 100%, ${sliderPosition}% 100%)`};
  /* Eliminado transition para mayor velocidad */
`;

// Control deslizante del slider como bolita circular con ondas de pulso
const SliderHandle = styled.div`
  position: absolute;
  top: 50%; /* Centrar verticalmente */
  left: ${({ sliderPosition }) => `calc(${sliderPosition}% - 15px)`}; /* Centrar horizontalmente */
  width: 30px;
  height: 30px;
  background-color: #e67e22; /* Color de la bolita */
  border-radius: 50%;
  cursor: ew-resize;
  z-index: 5; /* Asegurar que esté encima de las imágenes */
  display: flex;
  align-items: center;
  justify-content: center;
  transform: translateY(-50%); /* Ajuste para centrar verticalmente */
  touch-action: none; /* Mejora la respuesta en dispositivos táctiles */

  /* Pseudo-elemento ::before para la primera onda */
  &::before {
    content: '';
    position: absolute;
    width: 30px;
    height: 30px;
    background-color: rgba(230, 126, 34, 0.5);
    border-radius: 50%;
    transform: translate(-50%, -50%);
    opacity: 0;
    animation: ${({ hasInteracted }) => !hasInteracted && css`${pulse} 2s infinite`};
  }

  /* Pseudo-elemento ::after para la segunda onda */
  &::after {
    content: '';
    position: absolute;
    width: 30px;
    height: 30px;
    background-color: rgba(230, 126, 34, 0.5);
    border-radius: 50%;
    transform: translate(-50%, -50%);
    opacity: 0;
    animation: ${({ hasInteracted }) => !hasInteracted && css`${pulse} 2s infinite`};
    animation-delay: 1s; /* Retardo para crear un efecto alternado */
  }

  /* Desactivar animaciones si hasInteracted es true */
  ${({ hasInteracted }) => hasInteracted && css`
    &::before,
    &::after {
      animation: none;
      opacity: 0;
    }
  `}
`;

// Etiqueta para "Antes"
const AntesLabel = styled.span`
  position: absolute;
  top: 10px;
  left: 10px;
  background-color: rgba(44, 62, 80, 0.7); /* Fondo semi-transparente */
  color: #fff;
  padding: 5px 10px;
  border-radius: 5px;
  font-weight: bold;
  z-index: 5; /* Asegura que esté encima de las imágenes */

  @media (max-width: 768px) {
    font-size: 0.9em;
    padding: 4px 8px;
  }
`;

// Etiqueta para "Después"
const DespuesLabel = styled.span`
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: rgba(44, 62, 80, 0.7); /* Fondo semi-transparente */
  color: #fff;
  padding: 5px 10px;
  border-radius: 5px;
  font-weight: bold;
  z-index: 5; /* Asegura que esté encima de las imágenes */

  @media (max-width: 768px) {
    font-size: 0.9em;
    padding: 4px 8px;
  }
`;

const BeforeAfterSlider = ({ beforeImage, afterImage, title }) => {
  const { t } = useTranslation(); // Obtiene la función de traducción
  const containerRef = useRef(null);
  const [sliderPosition, setSliderPosition] = useState(50); // Porcentaje inicial del slider
  const [isDragging, setIsDragging] = useState(false);
  const [hasInteracted, setHasInteracted] = useState(false); // Nuevo estado para rastrear la interacción

  // Actualiza la posición del slider basado en la posición del cursor
  const updateSliderPosition = useCallback((clientX) => {
    const container = containerRef.current;
    if (!container) return;

    const rect = container.getBoundingClientRect();
    let offsetX = clientX - rect.left;
    let newPosition = (offsetX / rect.width) * 100;

    // Limitar el slider entre 0 y 100
    newPosition = Math.min(Math.max(newPosition, 0), 100);

    setSliderPosition(newPosition);
  }, []);

  const handleMouseDown = useCallback((e) => {
    e.preventDefault(); // Previene selección de texto u otros comportamientos
    setIsDragging(true);
    setHasInteracted(true); // Usuario ha interactuado
  }, []);

  const handleTouchStart = useCallback((e) => {
    e.preventDefault(); // Previene desplazamiento de la página u otros comportamientos
    setIsDragging(true);
    setHasInteracted(true); // Usuario ha interactuado
  }, []);

  const handleMouseMove = useCallback((e) => {
    if (!isDragging) return;
    updateSliderPosition(e.clientX);
  }, [isDragging, updateSliderPosition]);

  const handleTouchMove = useCallback((e) => {
    if (!isDragging) return;
    updateSliderPosition(e.touches[0].clientX);
  }, [isDragging, updateSliderPosition]);

  const handleMouseUp = useCallback(() => {
    setIsDragging(false);
  }, []);

  useEffect(() => {
    if (isDragging) {
      window.addEventListener('mousemove', handleMouseMove);
      window.addEventListener('mouseup', handleMouseUp);
      window.addEventListener('touchmove', handleTouchMove, { passive: false });
      window.addEventListener('touchend', handleMouseUp);
    } else {
      window.removeEventListener('mousemove', handleMouseMove);
      window.removeEventListener('mouseup', handleMouseUp);
      window.removeEventListener('touchmove', handleTouchMove);
      window.removeEventListener('touchend', handleMouseUp);
    }

    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
      window.removeEventListener('mouseup', handleMouseUp);
      window.removeEventListener('touchmove', handleTouchMove);
      window.removeEventListener('touchend', handleMouseUp);
    };
  }, [isDragging, handleMouseMove, handleTouchMove, handleMouseUp]);

  return (
    <>
      {title && <h2 style={{ textAlign: 'center' }}>{title}</h2>}
      <SliderContainer ref={containerRef} hasInteracted={hasInteracted}>
        {/* Etiquetas "Antes" y "Después" */}
        <AntesLabel>{t('Before')}</AntesLabel>
        <DespuesLabel>{t('After')}</DespuesLabel>

        {/* Barrita */}
        <SliderBar sliderPosition={sliderPosition} hasInteracted={hasInteracted} />

        <AfterImage src={afterImage} alt={t('After')} sliderPosition={sliderPosition} />
        <BeforeImage src={beforeImage} alt={t('Before')} sliderPosition={sliderPosition} />

        <SliderHandle
          sliderPosition={sliderPosition}
          onMouseDown={handleMouseDown}
          onTouchStart={handleTouchStart}
          hasInteracted={hasInteracted} // Pasar la prop
          aria-label={t('Deslizar para comparar')}
          role="slider"
          aria-valuemin="0"
          aria-valuemax="100"
          aria-valuenow={sliderPosition}
        />
      </SliderContainer>
    </>
  );
};

BeforeAfterSlider.propTypes = {
  beforeImage: PropTypes.string.isRequired,
  afterImage: PropTypes.string.isRequired,
  title: PropTypes.string,
};

export default BeforeAfterSlider;
