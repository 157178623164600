// src/components/WhatsAppButton.jsx
import React, { useState } from 'react';
import styled, { keyframes } from 'styled-components';
import PropTypes from 'prop-types';
import { FaWhatsapp } from 'react-icons/fa';

const pulse = keyframes`
  0% {
    transform: scale(1);
    opacity: 0.7;
  }
  10% {
    transform: scale(2);
    opacity: 0;
  }
  100% {
    transform: scale(2);
    opacity: 0;
  }
`;

// Botón de WhatsApp estilizado con ondas de pulso
const Button = styled.a`
  position: fixed;
  width: 60px;
  height: 60px;
  bottom: 40px;
  right: 40px;
  background-color: #25d366;
  color: #fff;
  border-radius: 50%;
  text-align: center;
  font-size: 30px;
  z-index: 1000;
  box-shadow: 2px 2px 3px #999;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.3s, transform 0.3s;
  overflow: visible; /* Permite que las ondas se muestren fuera del botón */
  
  /* Eliminar tap highlight en dispositivos móviles */
  -webkit-tap-highlight-color: transparent;

  /* Eliminar outline al recibir foco */
  outline: none;

  /* Aplicar animación de pulso siempre */
  &::before {
    content: '';
    position: absolute;
    width: 60px;
    height: 60px;
    background-color: rgba(37, 211, 102, 0.5);
    border-radius: 50%;
    transform: translate(-50%, -50%);
    opacity: 0;
    animation: ${pulse} 10s infinite;
  }

  &::after {
    content: '';
    position: absolute;
    width: 60px;
    height: 60px;
    background-color: rgba(37, 211, 102, 0.5);
    border-radius: 50%;
    transform: translate(-50%, -50%);
    opacity: 0;
    animation: ${pulse} 10s infinite;
    animation-delay: 5s; /* Retardo para la segunda onda */
  }

  &:hover {
    background-color: #128c7e;
    transform: scale(1.1);
  }

  /* Indicador de foco personalizado para accesibilidad */
  &:focus {
    // box-shadow: 0 0 0 3px rgba(37, 211, 102, 0.5);
  }

  @media (max-width: 768px) {
    width: 50px;
    height: 50px;
    bottom: 20px;
    right: 20px;
    font-size: 24px;

    &::before,
    &::after {
      width: 50px;
      height: 50px;
    }
  }
`;

const WhatsAppButton = ({ phoneNumber, message }) => {

  // Formatear el número y el mensaje para la URL de WhatsApp
  const formattedMessage = encodeURIComponent(message);
  const url = `https://wa.me/${phoneNumber}?text=${formattedMessage}`;

  // Manejar la interacción del usuario (opcional si quieres detener el pulso al interactuar)
  // const [hasInteracted, setHasInteracted] = useState(false);
  //
  // const handleClick = () => {
  //   setHasInteracted(true);
  // };

  return (
    <Button
      href={url}
      target="_blank"
      rel="noopener noreferrer"
      aria-label="Contactar por WhatsApp"
      // hasInteracted={hasInteracted} // No es necesario si el pulso siempre está activo
      // onClick={handleClick} // No es necesario si el pulso siempre está activo
    >
      <FaWhatsapp size="32"/>
    </Button>
  );
};

WhatsAppButton.propTypes = {
  phoneNumber: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
};

export default WhatsAppButton;
