// src/App.js
import React from 'react';
import { useTranslation } from 'react-i18next';
import Header from './components/Header';
import Hero from './components/Hero';
import MissionObjectives from './components/MissionObjectives';
import Testimonials from './components/Testimonials';
import CTA from './components/CTA';
import Footer from './components/Footer';
import './i18n';
import DoubleImageComparison from './components/DoubleComparisonContainer';
import WhatsAppButton from './components/WhatsAppButton';
import SensorDisplay from './components/SensorDisplay';
import BlurFade from './components/BlurFade';


function App() {
  const { t } = useTranslation();

  return (
    <div>
      {/* <SensorDisplay /> */}
      <Header />
      <Hero />
      <BlurFade>
        <MissionObjectives />
      </BlurFade>
      <DoubleImageComparison />
      <Testimonials />
      <CTA />
      <WhatsAppButton
        phoneNumber={process.env.REACT_APP_MAIN_TLF_NO_SPACE} 
        message={t('wsp_msg_pre')}
      />
      <Footer />
    </div>
  );
}

export default App;
