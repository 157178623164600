// src/components/Footer.jsx
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import FooterPoweredBy from './FooterPoweredBy';

const FooterContainer = styled.footer`
  background-color: #34495e;
  color: #fff;
  padding: 20px 10%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
`;

const Section = styled.div`
display: flex;
flex-direction: column;
  margin: 10px;
  flex: 1;
  min-width: 200px;
  width: min-content;
  align-items: center;
`;

const Title = styled.h4`
    // margin-block-start: 0;
  margin-bottom: 10px;
`;

const Link = styled.a`
  color: #fff;
  text-decoration: none;
  display: block;
  margin-bottom: 5px;
  transition: color 0.3s;

  &:hover {
    color: #e67e22;
    cursor: pointer;
  }
`;

const SocialLink = styled.a`
  display: inline-block;
`;

const SocialIcon = styled.img`
  width: 30px;
  height: 30px;
`;

const Footer = () => {
  const { t } = useTranslation();

  const mainEmail = process.env.REACT_APP_MAIN_EMAIL;
  const mainTLF = process.env.REACT_APP_MAIN_TLF;
  const mainDir = process.env.REACT_APP_MAIN_DIR;
  const linkStyle = { textDecoration: "none", color: "inherit" };
  const useGetUserAgent = () => {
    const [deviceType, setDeviceType] = useState('PC');

    useEffect(() => {
      const userAgent = navigator.userAgent || navigator.vendor || window.opera;

      if (/android/i.test(userAgent)) {
        setDeviceType('Android');
      } else if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
        setDeviceType('iOS');
      } else if (/windows phone/i.test(userAgent)) {
        setDeviceType('Windows Phone');
      } else {
        setDeviceType('PC');
      }
    }, []);

    return deviceType;
  };
  const isPhone = useGetUserAgent() === "PC" ? false : true;
  console.log(isPhone);
  const sectionsPhone = { alignItems: isPhone ? "start" : "center !important" };
  const handleScroll = (id) => {
    const element = document.getElementById(id);
    if (element) {
      const headerOffset = 90; // Debe coincidir con la altura del Header
      const elementPosition = element.getBoundingClientRect().top;
      const offsetPosition = elementPosition + window.pageYOffset - headerOffset;

      window.scrollTo({
        top: offsetPosition,
        behavior: 'smooth',
      });
    }
  };
  const segmentLine = () => (
    <hr
      style={{
        top: 0,
        height: "2px",
        width: "100%",
        minWidth: "18rem",
        borderTop: 0,
        borderLeft: 0,
        borderRight: 0,
        background: "linear-gradient(to right, transparent, white, transparent)",
        backgroundPosition: "center",
        marginTop: "2.25rem" /* Equivale a 'md:my-9' (solo en dispositivos medianos o más grandes) */

      }}
    />
  );
  return (
    <>
      <FooterContainer>
        <Section className="contacto" style={sectionsPhone}>
          <div>
            <Title>{t('Contacto')}</Title>
            {/* <p>{t('direccion')} {mainDir}</p> */}
            <p><a href={`tel: ${mainTLF}`} style={linkStyle}>{t('telefono')} {mainTLF}</a></p>
            <p><a href={`mailto: ${mainEmail}`} style={linkStyle}>{t('email')} {mainEmail}</a></p>
          </div>
        </Section>
        <Section className="enlaces" style={sectionsPhone}>
          <div>
            <Title>{t('Enlaces Rápidos')}</Title>
            <Link onClick={() =>
              window.scrollTo({ top: 0 })
            }>{t('Inicio')}</Link>
            <Link onClick={() => handleScroll('mision-objetivos')}>{t('Misión y Objetivos')}</Link>
            <Link onClick={() => handleScroll('comparaciones')}>{t('Comparaciones')}</Link>
            {/* <Link href="#servicios">{t('Servicios')}</Link> */}
            <Link onClick={() => handleScroll('testimonios')}>{t('Testimonios')}</Link>
            <Link onClick={() => handleScroll('contacto')}>{t('Contacto')}</Link>
          </div>
        </Section>
        {segmentLine()}
        <Section className="redes">
          <div style={{ display: "grid", justifyContent: "center !important", gridTemplateColumns: "40px 40px 40px" }}>
            <Title style={{ textAlign: "center", gridRow: 1, gridColumn: "1/4" }}>{t('Síguenos')}</Title>
            <SocialLink href="#" style={{ display: "flex", justifyContent: "start", gridRow: 2, gridColumn: 1 }}>
              <SocialIcon src="https://resources.tapichapa.com/social/Facebook_White.svg" alt="Facebook" />
            </SocialLink>
            <SocialLink href="#" style={{ display: "flex", justifyContent: "center", gridRow: 2, gridColumn: 2 }}>
              <SocialIcon src="https://resources.tapichapa.com/social/Instagram_Glyph_White.svg" alt="Instagram" />
            </SocialLink>
            <SocialLink href="#" style={{ display: "flex", justifyContent: "end", gridRow: 2, gridColumn: 3 }}>
              <SocialLink href="#">
                <SocialIcon src="https://resources.tapichapa.com/social/X_White.svg" alt="Twitter" />
              </SocialLink>
            </SocialLink>
          </div>
        </Section>
      </FooterContainer>
      <FooterPoweredBy isPhone={isPhone} justifyFooter={"start"} />
    </>
  );
};

export default Footer;
