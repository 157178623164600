// src/components/Hero.jsx
import React, { useEffect, useState } from 'react';
import styled, { keyframes } from 'styled-components';
import { useTranslation } from 'react-i18next';

// Animación de zoom out
const zoomOut = keyframes`
  from {
    transform: scale(1.1); /* Comienza con zoom */
  }
  to {
    transform: scale(1); /* Termina en tamaño normal */
  }
`;

const HeroSection = styled.section`
  height: 100dvh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: #fff;
  position: relative;
  overflow: hidden;
`;

const Title = styled.h1`
  font-size: 3em;
  margin-bottom: 20px;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
`;

const Button = styled.button`
  padding: 10px 20px;
  font-size: 1em;
  border: none;
  background-color: #e67e22;
  color: #fff;
  cursor: pointer;
  transition: background-color 0.3s;
  border-radius: 4px;

  &:hover {
    background-color: #d35400;
  }
`;

const BackgroundImage = styled.div`
  background: ${({ isPhone }) =>
    isPhone
      ? "url(https://resources.tapichapa.com/landing/hero_phone.webp) no-repeat center"
      : "url(https://resources.tapichapa.com/landing/hero_pc.webp) no-repeat center"};
  background-size: 120%; /* Incrementa el tamaño para permitir el desplazamiento */
  height: ${({ isPhone }) => (isPhone ? "115vh" : "100%")};
  width: ${({ isPhone }) => (isPhone ? "200%" : "100%")};
  position: fixed;
  top: 0;
  left: 0;
  z-index: -1;
  overflow: hidden;
  animation: ${zoomOut} 2s ease forwards;
  transition: transform 0.1s ease-out; /* Suaviza el movimiento */
  transform: ${({ x, y }) => `translate(${x}%, ${y}%)`}; /* Movimiento suave */

  &::after {
    content: '';
    top: 0;
    left: 0;
    position: fixed;
    // background: red;
    background: black;
    // background: radial-gradient(#f69d3c, #3f87a6);
    // backdrop-filter: blur(10px);
    filter: drop-shadow(30%);
    filter: opacity(16%);
    height: 10%;
    width: ${({ isPhone }) => (isPhone ? "200%" : "100%")};
    height: ${({ isPhone }) => (isPhone ? "115vh" : "100%")};
    z-index: -2; /* Asegura que esté debajo de otros elementos pero encima del fondo */
    /* Asegúrate de cerrar correctamente el gradiente si lo usas */
  }
`;

const Hero = () => {
  const { t } = useTranslation();
  const [isPhone, setIsPhone] = useState(false);
  const [position, setPosition] = useState({ x: 0, y: 0 });

  const scrollToSection = (id) => {
    const section = document.getElementById(id);
    if (section) {
      section.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const handleDeviceOrientation = (event) => {
    const xTilt = Math.min(Math.max(event.gamma, -20), 20) / 20; // Entre -1 y 1
    const yTilt = Math.min(Math.max(event.beta, -20), 20) / 20;  // Entre -1 y 1
    setPosition({ x: xTilt * 10, y: yTilt * 10 }); // Multiplicado para dar un efecto de profundidad
  };

  const handleMouseMove = (event) => {
    const xPos = ((event.clientX / window.innerWidth) - 0.5) * 20; // Mueve entre -10 y 10%
    const yPos = ((event.clientY / window.innerHeight) - 0.5) * 20; // Mueve entre -10 y 10%
    setPosition({ x: xPos, y: yPos });
  };

  const checkUserAgent = () => {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;
    setIsPhone(/android|ipad|iphone|ipod|windows phone/i.test(userAgent));
  };

  useEffect(() => {
    checkUserAgent();

    if (isPhone) {
      window.addEventListener('deviceorientation', handleDeviceOrientation);
    } else {
      window.addEventListener('mousemove', handleMouseMove);
    }

    // Limpieza de eventos
    return () => {
      if (isPhone) {
        window.removeEventListener('deviceorientation', handleDeviceOrientation);
      } else {
        window.removeEventListener('mousemove', handleMouseMove);
      }
    };
  }, [isPhone]);

  const titleContainerStyle = {
    position: "absolute",
    display: "flex",
    justifyContent: "center",
    borderRadius: "8px",
    top: isPhone ? "27vh" : "40vh",
    maxHeight: "50vh",
  };

  return (
    <HeroSection id="inicio">
      <BackgroundImage isPhone={isPhone} x={position.x} y={position.y} />
      <div style={titleContainerStyle}>
        <div
          style={{
            background: "hsla(0, 0%, 0%, 0.7)",
            maxWidth: "90%",
            paddingBottom: "1.66em",
            borderRadius: 8,
          }}
        >
          <Title>{t('slogan')}</Title>
          <Button onClick={() => scrollToSection('mision-objetivos')}>
            {t('Conoce Más')}
          </Button>
        </div>
      </div>
    </HeroSection>
  );
};

export default Hero;
