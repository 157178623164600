// src/components/CTA.jsx
import React from 'react';
import styled, { keyframes } from 'styled-components';
import { useTranslation } from 'react-i18next';
import emailjs from 'emailjs-com'; // Importa emailjs-com

// Definir la animación de pulso
const pulse = keyframes`
  0% {
    transform: scale(1);
    opacity: 0.7;
  }
  40% {
    transform: scale(1.5);
    opacity: 0;
  }
  100% {
    transform: scale(1.5);
    opacity: 0;
  }
`;

// Estilos del componente

const Section = styled.section`
  padding: 50px 10%;
  background-color: #2c3e50;
  color: #fff;
  text-align: center;
`;

const Title = styled.h2`
  margin-bottom: 20px;
  font-size: 2em;
`;

const Button = styled.button`
  position: relative; /* Necesario para posicionar los pseudo-elementos */
  padding: 15px 30px;
  border: none;
  background-color: #e67e22;
  color: #fff;
  font-size: 1em;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.3s;
  border-radius: 4px;
  overflow: visible; /* Permite que las ondas se muestren fuera del botón */

  &:hover {
    background-color: #d35400;
    transform: scale(1.1);
  }

  /* Pseudo-elemento ::before para la primera onda */
  &::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background-color: rgba(230, 126, 34, 0.5);
    border-radius: 4px; /* Coincide con el border-radius del botón */
    transform: translate(-50%, -50%) scale(1);
    opacity: 0;
    animation: ${pulse} 2s infinite;
  }

  /* Pseudo-elemento ::after para una segunda onda */
  &::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background-color: rgba(230, 126, 34, 0.5);
    border-radius: 4px; /* Coincide con el border-radius del botón */
    transform: translate(-50%, -50%) scale(1);
    opacity: 0;
    animation: ${pulse} 2s infinite;
    animation-delay: 1s; /* Retardo para la segunda onda */
  }

  /* Indicador de foco personalizado para accesibilidad */
  &:focus {
    box-shadow: 0 0 0 3px rgba(230, 126, 34, 0.5);
    outline: none; /* Eliminar el outline predeterminado */
  }

  @media (max-width: 768px) {
    padding: 10px 20px;
    font-size: 0.9em;

    &::before,
    &::after {
      width: 100%;
      height: 100%;
    }
  }
`;

const FormContainer = styled.div`
  margin-top: 30px;
  background-color: #34495e;
  padding: 30px;
  border-radius: 8px;
  display: ${({ isVisible }) => (isVisible ? 'block' : 'none')};
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Input = styled.input`
  width: 100%;
  max-width: 500px;
  padding: 10px;
  margin: 10px 0;
  border: none;
  border-radius: 4px;
`;

const TextArea = styled.textarea`
  width: 100%;
  max-width: 500px;
  padding: 10px;
  margin: 10px 0;
  border: none;
  border-radius: 4px;
`;

const SubmitButton = styled.button`
  padding: 10px 20px;
  border: none;
  background-color: #e67e22;
  color: #fff;
  cursor: pointer;
  transition: background-color 0.3s;
  border-radius: 4px;
  margin-top: 10px;

  &:hover {
    background-color: #d35400;
  }
`;

const FeedbackMessage = styled.p`
  margin-top: 20px;
  color: ${({ isError }) => (isError ? '#e74c3c' : '#2ecc71')};
  font-weight: bold;
`;

class CTA extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isVisible: false,
      feedback: '',
      isError: false,
    };
    this.form = React.createRef();
  }

  toggleForm = () => {
    this.setState(
      { isVisible: !this.state.isVisible, feedback: '', isError: false },
      () => {
        if (this.state.isVisible && this.contactForm) {
          const element = document.getElementById("form");
          if (element) {
            const headerOffset = 100; // Debe coincidir con la altura del Header
            const elementPosition = element.getBoundingClientRect().top;
            const offsetPosition = elementPosition + window.pageYOffset - headerOffset;

            window.scrollTo({
              top: offsetPosition,
              behavior: 'smooth',
            });
          }
        }
      }
    );
  };

  handleSubmit = (e) => {
    e.preventDefault();

    // Configura tus IDs de EmailJS
    const serviceID = 'YOUR_SERVICE_ID';
    const templateID = 'YOUR_TEMPLATE_ID';
    const userID = 'YOUR_USER_ID';

    emailjs
      .sendForm(serviceID, templateID, this.form.current, userID)
      .then(
        (result) => {
          this.setState({
            feedback: this.props.t('Mensaje enviado con éxito.'),
            isError: false,
          });
          this.form.current.reset();
        },
        (error) => {
          this.setState({
            feedback: this.props.t('Ocurrió un error. Por favor, intenta nuevamente.'),
            isError: true,
          });
          console.error('EmailJS Error:', error);
        }
      );
  };

  render() {
    const { t } = this.props;
    const { isVisible, feedback, isError } = this.state;
    return (
      <Section id="contacto">
        <Title>{t('Pide una Cita Hoy')}</Title>
        <Button onClick={this.toggleForm}>{t('Contactar')}</Button>
        <FormContainer isVisible={isVisible} ref={(el) => (this.contactForm = el)} id='form'>
          <Form ref={this.form} onSubmit={this.handleSubmit}>
            <Input type="text" name="nombre" placeholder={t('Tu Nombre')} required />
            <Input type="email" name="email" placeholder={t('Tu Email')} required />
            <TextArea name="mensaje" rows="4" placeholder={t('Tu Mensaje')} required></TextArea>
            <SubmitButton type="submit">{t('Enviar')}</SubmitButton>
          </Form>
          {feedback && <FeedbackMessage isError={isError}>{feedback}</FeedbackMessage>}
        </FormContainer>
      </Section>
    );
  }
}

// Componente funcional para envolver CTA con traducciones
const WrappedCTA = () => {
  const { t } = useTranslation();
  return <CTA t={t} />;
};

export default WrappedCTA;
