// src/components/SensorDisplay.jsx

import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

const SensorContainer = styled.div`
  padding: 20px;
  background-color: #222;
  color: #fff;
  font-family: sans-serif;
  min-height: 100vh;
`;

const SensorBlock = styled.div`
  margin-bottom: 20px;
  border-bottom: 1px solid #444;
  padding-bottom: 10px;
`;

const SensorTitle = styled.h2`
  font-size: 1.5em;
  margin-bottom: 10px;
`;

const SensorData = styled.pre`
  background-color: #333;
  padding: 10px;
  border-radius: 5px;
  overflow-x: auto;
`;

const ErrorMessage = styled.p`
  color: #e74c3c;
`;

const RequestPermissionButton = styled.button`
  padding: 10px 20px;
  font-size: 1em;
  background-color: #3498db;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-bottom: 20px;

  &:hover {
    background-color: #2980b9;
  }
`;

const SensorDisplay = () => {
  const [deviceMotionData, setDeviceMotionData] = useState(null);
  const [deviceOrientationData, setDeviceOrientationData] = useState(null);
  const [errors, setErrors] = useState([]);
  const [permissionsGranted, setPermissionsGranted] = useState(false);

  // DeviceMotionEvent
  useEffect(() => {
    const handleDeviceMotion = (event) => {
      setDeviceMotionData({
        acceleration: event.acceleration,
        accelerationIncludingGravity: event.accelerationIncludingGravity,
        rotationRate: event.rotationRate,
        interval: event.interval,
      });
    };

    if (permissionsGranted) {
      if (window.DeviceMotionEvent) {
        window.addEventListener('devicemotion', handleDeviceMotion);
      } else {
        setErrors((prev) => [...prev, 'DeviceMotionEvent no es compatible con este navegador.']);
      }
    }

    return () => {
      window.removeEventListener('devicemotion', handleDeviceMotion);
    };
  }, [permissionsGranted]);

  // DeviceOrientationEvent
  useEffect(() => {
    const handleDeviceOrientation = (event) => {
      setDeviceOrientationData({
        alpha: event.alpha,
        beta: event.beta,
        gamma: event.gamma,
        absolute: event.absolute,
      });
    };

    if (permissionsGranted) {
      if (window.DeviceOrientationEvent) {
        window.addEventListener('deviceorientation', handleDeviceOrientation);
      } else {
        setErrors((prev) => [...prev, 'DeviceOrientationEvent no es compatible con este navegador.']);
      }
    }

    return () => {
      window.removeEventListener('deviceorientation', handleDeviceOrientation);
    };
  }, [permissionsGranted]);

  // Request permissions
  const requestPermissions = async () => {
    try {
      if (
        typeof DeviceMotionEvent !== 'undefined' &&
        typeof DeviceMotionEvent.requestPermission === 'function'
      ) {
        const response = await DeviceMotionEvent.requestPermission();
        if (response === 'granted') {
          setPermissionsGranted(true);
        } else {
          setErrors((prev) => [...prev, 'Permiso denegado para DeviceMotionEvent.']);
        }
      } else {
        // No es necesario solicitar permiso
        setPermissionsGranted(true);
      }
    } catch (error) {
      setErrors((prev) => [...prev, `Error al solicitar permisos: ${error.message}`]);
    }
  };

  return (
    <SensorContainer>
      <h1>Sensores Disponibles</h1>

      {!permissionsGranted && (
        <RequestPermissionButton onClick={requestPermissions}>
          Solicitar Permisos
        </RequestPermissionButton>
      )}

      {errors.length > 0 && (
        <SensorBlock>
          <SensorTitle>Errores</SensorTitle>
          {errors.map((error, index) => (
            <ErrorMessage key={index}>{error}</ErrorMessage>
          ))}
        </SensorBlock>
      )}

      <SensorBlock>
        <SensorTitle>DeviceMotionEvent</SensorTitle>
        {deviceMotionData ? (
          <SensorData>{JSON.stringify(deviceMotionData, null, 2)}</SensorData>
        ) : (
          <p>No disponible o esperando datos...</p>
        )}
      </SensorBlock>

      <SensorBlock>
        <SensorTitle>DeviceOrientationEvent</SensorTitle>
        {deviceOrientationData ? (
          <SensorData>{JSON.stringify(deviceOrientationData, null, 2)}</SensorData>
        ) : (
          <p>No disponible o esperando datos...</p>
        )}
      </SensorBlock>
    </SensorContainer>
  );
};

export default SensorDisplay;
