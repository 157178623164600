// src/components/Footer.jsx
import React from 'react';
import './LegalFooter.css';

const LegalFooter = () => {

  return (
    <div className='legalFooter'>
      <img src='https://resources.tapichapa.com/legal/Kit1.png' />
      <img src='https://resources.tapichapa.com/legal/Kit2.png' />
    </div>
  );
};

export default LegalFooter;
